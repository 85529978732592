<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <!-- end test -->
        <div class="va-card">
          <div class="row">
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header">Customers</h4>
                  <div class="">
                    <div class="btn-toolbar" v-if="user.role.id == 1 || user.role.id == 2">

                      <router-link :to="{ name: 'new_loyalty_customer'}">
                        <button class="btn btn-default custom_btn custon_orange">
                          <i class="fa fa-plus"></i> New Customer
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="search_section">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group has-search mt-1">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="search" @keyup="searchItems()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..." class="form-control">
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group has-search mt-1">
                        <button class="btn btn-orange search">Search</button>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="row">
                            
                      <div class="col-md-6">
                        <select class="form-control mt-1" @change="searchItems()" v-model="typeFilter">
                          <option value="">All</option>
                          <option :value="true">Sold on its own</option>
                          <option :value="false">Not sold on its own</option>
                        </select>
                      </div>
                    </div> -->
                </div>
              </div>
              <hr>
            </div>
            <div class="col-lg-12">
              <div class="dash_card_body pt-0">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Email</th>
                        <th scope="col">Points </th>
                        <th scope="col">Created At</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading  && customers.length > 0">
                      <tr v-for="customer in customers" :key="customer.id">
                        <td>
                          <span>{{customer.name ? customer.name : 'N/A'}}</span>
                        </td>
                        <td>
                          <span>{{customer.phone_number}}</span>
                        </td>
                        <td>
                          <span>{{customer.email ? customer.email : 'N/A' }}</span>
                        </td>
                        <td>
                          {{customer.points}}
                        </td>
                        <td>
                          <span>{{formatDate(customer.created_at,1)}}</span>
                        </td>
                        <td>
                          <button class="btn btn-primary btn-sm mr-1" @click="getCustomerDetail(customer)" data-toggle="modal" :data-target="'#editCustomer_'+customer.id"><i class='fas fa-edit'></i></button>
                        </td>
                        <!-- modal for editing customer -->

                        <div class="modal fade" :id="'editCustomer_'+customer.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Edit {{customer.name ? customer.name : customer.phone_number}} Details</h5>
                                <button type="button" class="close" :id="'close_btn_'+customer.id" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <form @submit.prevent="editCustomer()">

                                <div class="modal-body">
                                  <div class="row">
                                    <div class="col-md-12" v-if="errors ">
                                      <div class="alert alert-danger">
                                        <ul>
                                          <li v-for="(err_msg,errindex) in errorMsg" :key="errindex">{{err_msg}}</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                
                                  <div class="form-group">
                                    <label for="recipient-name" class="col-form-label">Name:</label>
                                    <input type="text" class="form-control" v-model="name" id="recipient-name">
                                  </div>
                                  <div class="form-group">
                                    <label for="message-text" class="col-form-label">Email:</label>
                                    <input type="email" class="form-control" v-model="email" id="recipient-email">
                                  </div>

                                  <div class="form-group">
                                    <label for="message-text" class="col-form-label">Phone:</label>
                                    <input type="text" class="form-control" v-model="phone" id="recipient-phone">
                                  </div>

                                  <div class="form-group">
                                    <label for="message-text" class="col-form-label">Country Code:</label>
                                    <select class="form-control" id="" v-model="countryCode">
                                      <option value="KE">KE</option>
                                      <option value="TZ">TZ</option>
                                      <option value="UG">UG</option>
                                    </select>
                                  </div>
                               
                                </div>
                                <div class="modal-footer">
                                  <button type="submit" class="btn btn-orange"> <span v-if="spinner"><i class='fa fa-spinner fa-spin '></i></span> Update</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <!--  -->
                      </tr>

                    </tbody>
                    <tbody v-if="!loading  && customers.length == 0">
                      <tr>
                        <td colspan="5" style="text-align:center"> No Data</td>
                      </tr>
                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="8">
                          <div id="pagination" v-if="totalPages > 1">
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                </select>
                              </li>

                            </ul>
                          </div>

                        </td>
                      </tr>
                    </tfoot>
                  </table>

                </div>
              </div>
              <div v-if="loading" class="loading-cover">
                <div class="loader"> </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

      
      
<script>
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import Pagination from '@/pages/pagination/pagination.vue';
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
    components: {
        Pagination,
    },
    data() {
        return {
            errors: false,
            errorMsg: [],
            spinner:false,
            name: '',
            phone: '',
            countryCode: '',
            email: '',
            userId: '',
            customers: [],
            loading: false,
            page: 1,
            search: '',
            totalPages: 0,
            totalRecords: 0,
            recordsPerPage: 10,
            debounce: null,
        }
    },
    mounted: async function () {
        this.getCustomers();
    },
    methods: {
        searchItems() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getCustomers();
            }, 1500)
        },
        getCustomerDetail(customer) {
            // 
            this.name = customer.name;
            this.phone = customer.phone_number;
            this.countryCode = customer.country_code;
            this.email =customer.email;
            this.userId = customer.id;
        },
        async editCustomer(){
            try{
                this.errors = false;
                this.spinner = true;
                const url = `${this.dashBaseUrl}/create-loyalty-customer`;
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                const payload = {
                    'name': this.name,
                    'email': this.email,
                    'user_id': this.userId,
                    'phone': this.phone,
                    'country_code': this.countryCode,
                    'merchant_id': this.merchantID,
                }
                const response = await this.axios.post(url,payload,config)
                if(response.status == 201){
                    toast.success('Customer '+this.name+' has been updated successfully');
                    this.getCustomers();
                }
                document.getElementById('close_btn_'+this.userId).click();
                this.name = '';
                this.phone = '';
                this.countryCode = '';
                this.email = '';
                this.userId = '';
                this.spinner =false;

            }catch(error){
                document.getElementById('close_btn_'+this.userId).click();
                this.name = '';
                this.phone = '';
                this.countryCode = '';
                this.email = '';
                this.userId = '';
                this.spinner = false;
                if (error.response.status === 400) {
                      this.errors = true;
                      this.errorMsg = error.response.data.errors
                  }
                if (error.response.status === 401) {
                    this.$router.push({
                                name: 'login'
                    });
                }
            }
        },

        async getCustomers() {
            this.loading = true;
            let url = `${this.dashBaseUrl}/loyalty-customers`;
            url = new URL(url);
            url.searchParams.set('page', this.page);
            url.searchParams.set('size', this.recordsPerPage);
            url.searchParams.set('merchant_id', this.merchantID);
            if (this.search !== '') {
                url.searchParams.set('search', this.search);
            }

            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.loading = false;
                    if(response.data.data.data != undefined){
                        this.customers = response.data.data.data;
                    }
                    this.totalPages = Math.ceil(response.data.data.total / this.recordsPerPage)
                    this.totalRecords = response.data.data.total_count
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            }).finally(() => {
                this.loading = false;
                this.searchLoading = '';
            })
        },
        onPageChange(page) {
            this.page = page
            this.getCustomers();
        },
        onChangeRecordsPerPage() {
            this.getCustomers();
        },
    },
    mixins: [ReusableFunctionMixins, ReusableDataMixins]
}
</script>
